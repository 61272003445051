import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionHero from "./SectionHero";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionPromo3 from "components/SectionPromo3";
// import rightImg from "images/hero-right1.png";

import visionmission from "images/visionmission.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Tikasaheb</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <section className="pt-8 pb-14 lg:pb-20 xl:pb-20 relative z-10">
        <div className="container">
          
          {/* Breadcrumb */}
          <nav className="flex mb-4" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-[#be1e2d] transition duration-300">
                  <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                  Home
                </Link>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                  <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2">About Us</span>
                </div>
              </li>
            </ol>
          </nav>

          <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-left">
         👋 About Us
          </h2>

          <div >
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">

              'TikaSaheb' is something that we've waited for. Something that has made us count every moment, and we count on the name itself which means the world to us. It is a gift that hasn't been unwrapped yet, but the value is unexplainable. There is so much to reveal, but words always fall short. We shall be pleasing your eyes soon, though. Stay tuned!
            </p>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
              Since then, we have grown and expanded our business with the support of our loyal customers in villages across the country. This success was made possible through our tireless efforts to build a multi-layered distribution network comprising distributors, wholesalers, and retailers, ensuring our products reach every corner that matters most for our survival.
            </p>

            <h2 className="text-2xl font-semibold text-custom-pink mb-4">Vision & Mission</h2>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
            We believe that the end of something always opens a new door, for the sunlight to seep through. 'TikaSaheb' is that venture for us. It's a road that opened our eyes to bring to you, a new morning. This journey is so much more than a brand to us. It is an era, and we promise that it shall continue forever. Let's start this era of fashion, an iconic yug, together.
            </p>
            
            {/* <h2 className="text-xl font-medium text-custom-pink mb-2">Handcrafted Excellence</h2>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              Every item in our collection is a result of dedicated craftsmanship and attention to detail. From the selection of premium ingredients to the intricate blending processes, our bath bombs and salts are crafted with care to ensure the highest standards of quality. Our commitment to sustainability and ethical practices means you can indulge in our products with peace of mind, knowing they are made with respect for both people and the planet.
            </p> */}

          </div>

          {/* <div className="pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 items-center relative">

                <div className="flex-grow">
                  <img className="w-full" src={visionmission} alt="" />
                </div>

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content">
                    <div className="main-sub-lable">Vision</div>
                    <p>To be a globally recognized leader, inspiring positive change and innovation across diverse industries. Tikasaheb General Trading envisions a future where our comprehensive range of services transforms lives, businesses, and experiences, leaving an indelible mark on the world.</p>

                    <div className="pt-3"></div>

                    <div className="main-sub-lable">Mission</div>
                    <p>Tikasaheb General Trading is dedicated to delivering unparalleled excellence across Spice Trading, Organic Foods Handmade Bath and Body products, IT services, Investment Solutions, E-commerce, Handmade Bath and Body products and VR solutions. Our mission is to empower individuals and businesses through strategic, innovative, and sustainable practices. We are committed to fostering growth, embracing diversity, and exceeding expectations to create lasting value for our clients and communities.</p>
                  </div>
                </div>

          </div> */}

        </div>
      </section>

      {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionPromo3 />
      </div> */}
    </div>
  );
};

export default PageAbout;
