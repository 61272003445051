import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { NavLink } from "react-router-dom";

const renderPhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
    </svg>
  );
};

const renderEmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
    </svg>
  );
};

const renderLocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5  h-5 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
    </svg>

  );
};

interface Menu {
  href: string;
  label: string | JSX.Element;
}


export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: Menu[];
  classNameul: string;

}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Usefull Links",
    classNameul: "",
    menus: [
      { href: "/", label: "Home" },
      { href: "/about", label: "About" },
      { href: "/category", label: "Products" },
      { href: "/privacy", label: "Privacy Policy" },
    ],
  },
  {
    id: "2",
    title: "",
    classNameul: "",
    menus: [
      // { href: "/terms-and-conditions ", label: "Terms and Conditions" },
      // { href: "/faqs", label: "FAQ" },

      { href: "/return", label: "Refund Policy" },
      { href: "/contact", label: "Contact Us" },
    ],
  },
  {
    id: "4",
    classNameul: "hidden md:block",
    title: "Contact Info",
    menus: [
      {
        href: "tel:+91 8849748151",
        label: (
          <>
            <span className="text-custom-pink">{renderPhoneIcon()}</span> (+91) 8849748151
          </>
        ),
      },
      {
        href: "mailto:tikasaheb@gmail.com",
        label: (
          <>
            <span className="text-custom-pink">{renderEmailIcon()}</span> tikasaheb@gmail.com
          </>
        ),
      },
      {
        href: "",
        label: (
          <>
            <span className="text-custom-pink">{renderLocationIcon()}</span> Address : 3, 1st floor, Rivera Arcade, Above Union Bank, Prahaladnagar, Ahmedabad -380015
          </>
        ),
      },
      // Adding the social media links
      {
        href: "", // or any placeholder href
        label: (
          <div className="header-top-social hidden md:flex gap-3">
            <a href="https://www.facebook.com/tikasheb/"
              className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out"
              target="_blank" rel="noopener noreferrer">
              <i className="lab la-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/tikasaheb/"
              className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-pink-600 hover:text-white transition duration-300 ease-in-out"
              target="_blank" rel="noopener noreferrer">
              <i className="lab la-instagram"></i>
            </a>
            <a href="https://x.com/tikasaheb"
              className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-blue-400 hover:text-white transition duration-300 ease-in-out"
              target="_blank" rel="noopener noreferrer">
              <i className="lab la-twitter"></i>
            </a>
          </div>

        ),
      },
    ],
  }

];


const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className={`text-sm ${menu.classNameul}`}>
        <h2 className="font-semibold text-neutral-700">
          {menu.title} &nbsp;
        </h2>
        <ul className={`mt-5 space-y-4`}>

          {menu.menus.map((item, index) => (
            <li key={index} className="flex">
              <NavLink
                key={index}
                className={({ isActive }) => `${isActive ? "text-custom-pink" : "text-neutral-6000 hover:text-black"} flex`}
                to={item.href}
              // target="_blank"
              // rel="noopener noreferrer"
              >

                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative pt-10 md:pt-10 lg:pt-20 xl:pt-20 lg:pb-0 border-t border-neutral-200">
      <div className="container grid grid-cols-2 gap-y-5 md:gap-y-4 lg:gap-y-5 xl:gap-y-5  gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 ">

        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1 hidden lg:block">
            <Logo className="footer-logo" />
          </div>
          <div className=" col-span-2 hidden items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>

        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container mt-10 md:hidden">
        <div className="text-sm"><h2 className="font-semibold text-neutral-700">Contact Info &nbsp;</h2>
          <ul className="mt-5 space-y-4">
            <li className="flex"><span className="text-custom-pink">{renderPhoneIcon()}</span><a className="text-neutral-6000 hover:text-black" href="tel:+91 8849748151"> (+91) 8849748151</a></li>
            <li className="flex"><span className="text-custom-pink">{renderEmailIcon()}</span><a className="text-neutral-6000 hover:text-black" href="mailto:tikasaheb@gmail.com">tikasaheb@gmail.com</a></li>
            <li className="flex"><span className="text-custom-pink">{renderLocationIcon()}</span><a className="text-neutral-6000 hover:text-black" href="/manufacturing"> Address : 3, 1st floor, Rivera Arcade, Above Union Bank, Prahaladnagar, Ahmedabad -380015 </a></li>
            <li className="flex">
              <div className="header-top-social flex md:flex gap-2">
                <a href="https://www.facebook.com/tikasheb/"
                  className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out"
                  target="_blank" rel="noopener noreferrer">
                  <i className="lab la-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/tikasaheb/"
                  className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-pink-600 hover:text-white transition duration-300 ease-in-out"
                  target="_blank" rel="noopener noreferrer">
                  <i className="lab la-instagram"></i>
                </a>
                <a href="https://x.com/tikasaheb"
                  className="inline-flex items-center text-[18px] font-medium text-slate-700 py-2 px-2 rounded-full border border-gray-300 hover:bg-blue-400 hover:text-white transition duration-300 ease-in-out"
                  target="_blank" rel="noopener noreferrer">
                  <i className="lab la-twitter"></i>
                </a>
              </div>
            </li>

          </ul></div>
      </div>

      <div className="copy-rightfooter bg-slate-100">
        <div className="container mt-12">
          <div className="footer-bottom">
            <div className="copyright">© 2024 Tikasaheb. All Rights Reserved.</div>
            <div className="mede-with">Made with
              <span className="icons-box-made">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                </svg>
              </span>
              by <a href={"https://pmcommu.com/"} rel="noreferrer" target="_blank">PM Communications</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
