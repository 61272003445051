import React, { useState, FC,useEffect, useRef } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import * as $ from 'jquery';
import 'jquery-validation';
import { useAuth  } from '../../contains/AuthContext';
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import API_HOST from '../../config/config';

export interface PageSignUpProps {
  className?: string;
}

interface JQuery {
  $: any; // Replace with your types
}

const facebookSvg = () => {
  return(
    <svg className="w-[20px] h-[20px] mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z" fillRule="evenodd"></path>
    </svg>
  )
}

const googleSvg = () => {
  return(
  <svg className="w-[20px] h-[20px] mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z"></path>
  </svg>
  )
}

const hidepassSvg = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
  )
}

const viewpassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
  )
}

const loginSocials = [
  {
    name: "Facebook",
    href: "#",
    icon: facebookSvg(),
    extraCss: "text-white bg-[#2D88FF] hover:bg-[#0050b9]",
  },
  {
    name: "Google",
    href: "#",
    icon: googleSvg(),
    extraCss: "text-white bg-[#ea4436] hover:bg-[#dd1100]",
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const location_from = location.state?.from?.pathname || "/";

  const [errMsg, setErrMsg] = useState<any[]>([]);    
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [phoneValue, setPhoneValue] = useState<any>('');
  const [phoneMsg, setPhoneMsg] = useState<any>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleInputChange = (val: any) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
      setPhoneMsg('');
    }    
  };

  var phoneNumberCheckCount = 0;
  const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
      setPhoneMsg('');
    }
    if(phoneNumberCheckCount == 0)
    {
      setPhoneMsg('');
    }
    phoneNumberCheckCount = phoneNumberCheckCount+1;
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
  }

  useEffect(() => {
    // If the user is already logged in, redirect to another page
    if (user) {
      if (user?.token && user?.role && user?.role=='user') {
        // navigate('/');     
        window.location.href = location_from;
      }
    }
  }, [user, navigate]);
  
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      if(!formRef.current.checkValidity())
      {
        phoneNumberCheck();    
      }
      ($ as any)(formRef.current).validate({
        rules: {
          first_name: {
            required: true,
          },
          last_name: {
            required: true,
          },
          email: {
            required: true,
            email: true,
          },
          password: {
            required: true,
            minlength: 6,
          },
        },
        messages: {
          first_name: {
            required: 'First Name is required',
          },
          last_name: {
            required: 'Last Name is required',
          },
          email: {
            required: 'Email is required',
            email: 'Invalid email address',
          },
          password: {
            required: 'Password is required',
            minlength: 'Password must be at least 6 characters',
          },
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');
          if (element.attr("name") == "phone" ) {
            // error.appendTo(($ as any)(element).parents('div').find(($ as any)('.phone_input_div')));
          }
          else
          {
            error.insertAfter(element);
          }
        },        
      });
    }
  }, []);

  function checkPhone(value:any)
  {
    if(value != ""){
      const phone_result = (/^[0-9]{10}$/).test(value);         
      if(value.length <= 10 && !(/^[0-9]{11}$/).test(value))
      {
        setPhone(value);      
      }
      else
      {
        setPhone(phone);
      }
    }else{ 
      setPhone(value);
    }    
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg([]);
    phoneNumberCheck();
    if (formRef.current) {      
      if(formRef.current.checkValidity())
      {
        if(phoneNumberCheck() == true)
        {
          setErrMsg([]);
          const formData = new FormData(formRef.current);
          if((isValidPhoneNumber((phoneValue || ''))) == true)
          {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
              formData.append('phone', `${phoneNumber.number}`);
              formData.append('phone_country', `${phoneNumber.country}`);
            }
          }
          try {
            axios.post(API_HOST+'/api/register',formData)
            .then(response => {
              if(response.data.success === 0)
              {
                login(response.data.data);
                // const { from } = (location.state as { from: { pathname: string } }) || { from: { pathname: '/' } };
                // navigate(from);
                navigate('/login');
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Registration Failed";
              }
              else{
                newArray['server_error' as any] = "Registration Failed";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            var newArray: Array<any> = [];
            newArray['server_error' as any] = "Registration Failed";
            setErrMsg(newArray);
            console.error('Error fetching data:', error);
          }
        }
      }
      else
      {
        phoneNumberCheck();
      }
    }
  }

  useEffect(() => {},[errMsg]);

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Tikasaheb</title>
      </Helmet>
      <div className="container mt-12 mb-12 sm:mt-14 sm:mb-14 md:mt-16 md:mb-16 lg:mb-16 lg:mt-16 xl:mt-16 xl:mb-16">
        <h2 className="my-6 md:my-8 lg:my-8 xl:my-8 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid grid-cols-2 gap-3">
            {/* {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className={`
                flex justify-center 
                w-full rounded-lg bg-primary-50 px-3 py-3
                transition
                ${item.extraCss}
                `}
              >
                {item.icon}

                <h3 className="text-center text-[16px] font-medium">
                  {item.name}
                </h3>
              </a>
            ))} */}
          </div>
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          {
            (Object.keys(errMsg).length > 0) && (
              <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Error
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  {
                    Object.keys(errMsg).map((item:any) => (
                      <p>{errMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }

          <form ref={formRef} onSubmit={handleFormSubmit} encType="multipart/form-data" className="grid sm:grid-cols-2 gap-5 signupform">

            <label className="block">
              <span className="text-neutral-800 ">
                First name
              </span>
              <Input
                id="first_name"
                name="first_name"
                type="text"
                placeholder="Enter first name"
                className="mt-1 h-12"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 ">
                Last name
              </span>
              <Input
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Enter last name"
                className="mt-1 h-12"
              />
            </label>

            <label className="block phone_input_div">
              <span className="text-neutral-800 ">
                Phone Number
              </span>
              <PhoneInput
                inputclass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white  disabled:bg-neutral-200  rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 h-12`}
                international={false}
                defaultCountry="IN"
                id="phone"
                name="phone"
                value={phoneValue}
                onChange={handleInputChange}
              />

              {(phoneMsg) && <label id="" className={` block text-right p-1 text-[14px] text-[#fa826a]`} >{phoneMsg}</label>}
            </label>

            {/*<label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Input
                id="phone"
                name="phone"
                type="text"
                onChange={(e) => checkPhone(e.target.value)}
                value={phone}
                placeholder="Enter number"
                className="mt-1 h-12"
              />
            </label>*/}

            <label className="block">
              <span className="text-neutral-800 ">
                Email address
              </span>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Enter email address"
                className="mt-1 h-12"
              />
            </label>

            <label className="col-span-2 block relative">
              <span className="flex justify-between items-center text-neutral-800 ">
                Password
              </span>
              <Input type={showPassword ? 'text' : 'password'} id="password" name="password" placeholder="Enter Password" className="mt-1 pr-[45px] h-12" />
              <span onClick={togglePasswordVisibility} className="cursor-pointer flex justify-center align-middle w-[30px] h-[30px] absolute right-[12px] top-[39px]">
                {showPassword ? viewpassSvg() : hidepassSvg()}
              </span>
              
            </label>

            <ButtonPrimary className="col-span-2 mt-2" type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="text-[14px] block text-center text-neutral-700">
            Already have an account? {` `}
            <Link className="transition text-custom-pink hover:text-custom-pink" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
