import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";


const PageReturn: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Return Policy || Tikasaheb</title>
      </Helmet>

      <BgGlassmorphism />

      <section className="pt-8 pb-14 lg:pb-20 xl:pb-20 relative z-10">
                <div className="container">

                    {/* Breadcrumb */}
                    <nav className="flex mb-4" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-[#be1e2d] transition duration-300">
                                    <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </Link>
                            </li>
                            <li aria-current="page">
                                <div className="flex items-center">
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2">Return Policy</span>
                                </div>
                            </li>
                        </ol>
                    </nav>

                    <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-left">
                        👋 Return Policy
                    </h2>

                    <div className="">
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for an exchange only. Please see below for more information on our return policy.
                        </p>
                        
                        {/* <h2 className="text-2xl font-semibold text-custom-pink mb-4">RETURNS</h2> */}
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">RETURNS</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        All returns must be postmarked within 7 days or the purchase date. All returned items must be in new and unused condition, with all original tags and labels attached.
                        </p>
                    
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">RETURN PROCESS</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        To return an item, please email customer service at tikasaheb@gmail.com. After receiving the return confirmation, place the item securely in its original packaging and include your proof or purchase, then mail your return to the following address.
                        </p>

                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        TiIkasaheb<br />
                        Attn: Returns<br />
                        608, Colonnade - 2, B/H.Rajpath Club, Rajpath Rangoli Road, Bodakdev<br />
                        Ahmedabad, Gujarat 380054<br />
                        India
                        </p>

                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        Please note, you wIll be responsible for all return shipping charges. We strongly recommend that you use a trackable method to mail your return.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">EXCHANGE PERIOD</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        After receiving your return and inspecting the condition of your ftem, we will process your exchange.
                        </p>

                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        Please allow at least 10 days from the receipt of your item to procesS your exchange. We will notify you by email when your return has been processed.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">NO EXCHANGE</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        Note the following pointers why the product won't be exchanged at the time of exchange,
                        </p>

                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        Please Note:
                        </p>

                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">If the product is damaged or has stains at the time of return, it won't be accepted.</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">If the product is damaged during transit for exchange (i.e., it was not damaged when you sent it, but got damaged en route to us), it won't be accepted.</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">If the selected product for exchange is of lesser price than the original, it won't be accepted.</li>
                        </ul>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">QUESTIONS</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-sm md:text-base lg:text-md xl:text-md">
                        If you have any questions concerning our return policy, please contact us at:
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-sm md:text-base lg:text-md xl:text-md">
                        Phone: <a href="tel:+918849748151" className="underline hover:text-custom-pink transition duration-300 text-red-500">+91 8849748151</a>
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-sm md:text-base lg:text-md xl:text-md">
                        Email: <a href="mailto:tikasaheb@gmail.com" className="underline hover:text-custom-pink transition duration-300 text-red-500">tikasaheb@gmail.com</a>
                        </p>
                       
                    </div>

                  

                </div>
            </section>

  
    </div>
  );
};

export default PageReturn;
