import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { useCart } from '../context/CartContext';

export interface ProductCardProps {
  className?: string;
  data?: Product;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data = PRODUCTS[0],
  isLiked = false,
}) => {
  const {
    id,
    name,
    price,
    discount,
    description,
    summary,
    sizes,
    variants,
    variantType,
    status,
    image,
    hover_image,
    review_count,
    average_review,
    link,
    favourite
  } = data;
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const { addToCart } = useCart();

  const baseUrl = window.location.origin; // Get the base URL (e.g., http://localhost:3000)

const shareOnFacebook = () => {
  const shareUrl = `${baseUrl}${link}`;
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
};

const shareOnTwitter = () => {
  const shareUrl = `${baseUrl}${link}`;
  window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(name)}`, '_blank');
};

const shareOnWhatsApp = () => {
  const shareUrl = `${baseUrl}${link}`;
  const message = `Check out this product: ${name} - ${shareUrl}`;
  window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
};



  const notifyAddTocart = ({ size }: { size?: string }) => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white  shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5  text-slate-900"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 my-4" />
          {renderProductCartOnNotify({ size })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
                <p className="mt-1 text-sm text-slate-500">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200  h-4"></span>
                  <span>{size || "XL"}</span>
                </p>
              </div>
              <Prices price={price} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 ">Qty 1</p>

            <div className="flex">
              <Link
                to={"/cart"}
                className="font-medium text-custom-pink  "
              >
                View cart
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };

  const renderVariants = () => {
    if (!variants || !variants.length || !variantType) {
      return null;
    }

    if (variantType === "color") {
      return (
        <div className="flex space-x-1">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
                ? getBorderClass(variant.color)
                : "border-transparent"
                }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="flex ">
        {variants.map((variant, index) => (
          <div
            key={index}
            onClick={() => setVariantActive(index)}
            className={`relative w-11 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
              ? "border-custom-golden"
              : "border-transparent"
              }`}
            title={variant.name}
          >
            <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
              <img
                src={variant.thumbnail}
                alt="variant"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderGroupButtons = () => {
    return (
      <div className="absolute -bottom-6 inset-x-1 flex justify-center opacity-0 invisible group-hover:bottom-1.5 group-hover:opacity-100 group-hover:visible transition-all ease-in-out duration-700 hidden sm:block md:block lg:block xl:block">
        <ButtonPrimary
          className="shadow-lg transform border-0 translate-y-full group-hover:translate-y-0 transition-all duration-500 ease-in-out"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => addToCart(data)}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Add to Cart</span>
        </ButtonPrimary>

        {/* <ButtonSecondary
          className="ml-1.5 bg-white hover:text-slate-900 transition-colors shadow-lg transform translate-y-full group-hover:translate-y-0 transition-all duration-500 ease-in-out delay-100"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => setShowModalQuickView(true)}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Quick view</span>
        </ButtonSecondary> */}
      </div>
    );
  };

  const renderSizeList = () => {
    if (!sizes || !sizes.length) {
      return null;
    }

    return (
      <div className="absolute bottom-0 inset-x-1 space-x-1.5 flex justify-center opacity-0 invisible group-hover:bottom-4 group-hover:opacity-100 group-hover:visible transition-all">
        {sizes.map((size, index) => {
          return (
            <div
              key={index}
              className="nc-shadow-lg w-10 h-10 rounded-xl bg-white hover:bg-custom-golden hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900"
              onClick={() => notifyAddTocart({ size })}
            >
              {size}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className} transition-all duration-300 ease-in-out hover:shadow-lg p-2 group`}
        data-nc-id="ProductCard"
      >
        <Link to={link} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50  overflow-hidden z-1 group transition-all duration-300">
          <Link to={link} className="block">
            <div className="relative w-full h-full overflow-hidden group">
              {/* Main Image */}
              <NcImage
                containerClassName="flex w-full"
                src={image}
                className="object-cover w-full h-full drop-shadow-xl bg-white transition-all duration-1000 ease-in-out transform group-hover:scale-110 group-hover:opacity-0"
              />
              {/* Hover Image */}
              <NcImage
                containerClassName="flex w-full absolute inset-0"
                src={hover_image}
                className="object-cover w-full h-full drop-shadow-xl bg-white opacity-0 transition-all duration-1000 ease-in-out transform scale-110 group-hover:scale-100 group-hover:opacity-100"
              />
              {/* Light/Dark Mode Overlay */}
              <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-700 ease-in-out group-hover:opacity-10 "></div>
            </div>
          </Link>

          <LikeButton liked={favourite} className="absolute top-3 right-3 z-10" productId={id} />

          {/* Social Icons */}
          <div className="absolute bottom-5 left-0 right-0 flex justify-center space-x-1.5 sm:space-x-1.5 md:space-x-2.5 lg:space-x-2.5 xl:space-x-2.5">
  <button
    onClick={shareOnFacebook}
    aria-label="Share on Facebook"
    className="w-9 h-9 p-2 rounded-full flex items-center justify-center text-gray-600 bg-white transition-transform transform hover:scale-110 hover:bg-blue-600 hover:text-white shadow-md opacity-0 translate-y-10 transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    </svg>
  </button>
  <button
    onClick={shareOnTwitter}
    aria-label="Share on Twitter"
    className="w-9 h-9 p-2 rounded-full flex items-center justify-center text-gray-600 bg-white transition-transform transform hover:scale-110 hover:bg-blue-400 hover:text-white shadow-md opacity-0 translate-y-10 transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14z" />
    </svg>
  </button>
  <button
    onClick={shareOnWhatsApp}
    aria-label="Share on WhatsApp"
    className="w-9 h-9 p-2 rounded-full flex items-center justify-center text-gray-600 bg-white transition-transform transform hover:scale-110 hover:bg-green-500 hover:text-white shadow-md opacity-0 translate-y-10 transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="currentColor"><path d="M414.73 97.1A222.14 222.14 0 00256.94 32C134 32 33.92 131.58 33.87 254a220.61 220.61 0 0029.78 111L32 480l118.25-30.87a223.63 223.63 0 00106.6 27h.09c122.93 0 223-99.59 223.06-222A220.18 220.18 0 00414.73 97.1zM256.94 438.66h-.08a185.75 185.75 0 01-94.36-25.72l-6.77-4-70.17 18.32 18.73-68.09-4.41-7A183.46 183.46 0 0171.53 254c0-101.73 83.21-184.5 185.48-184.5a185 185 0 01185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52zm101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78-14.4 18-17.65 21.75-6.5 4.16-12.07 1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 00-14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38 39.3 59.73 95.21 83.76a323.11 323.11 0 0031.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2 10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37-5.11-3.71-10.69-6.48z" fill-rule="evenodd"/></svg>
  </button>
</div>

        </div>


        <div className="space-y-4 px-2 pt-4 pb-4 transition-all duration-300 text-center transform translate-y-0 ">
          <div>
            <h2
              className={`nc-ProductCard__title text-sm sm:text-base lg:text-base xl:text-base font-medium transition-colors line-clamp-2 text-black`}
            >
              {name}
            </h2>
          </div>

          <div className="flex flex-col gap-1.5 sm:gap-2.5 md:gap-2.5 lg:gap-2.5 xl:gap-2.5 justify-center items-center" style={{ marginTop: '8px' }}>
            <Prices price={price} discount={discount} />
            <div className="flex items-center mt-1 sm:mt-0 mb-0.5">
              <StarIcon className="w-5 h-5 pb-[1px] text-custom-golden" />
              <span className="text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm  ml-1 text-slate-500">
                {average_review} ({review_count} reviews)
              </span>
            </div>
          </div>

          {renderGroupButtons()}
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView data={data}
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
