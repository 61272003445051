import React, { useState } from "react";
import { useAuth } from '../contains/AuthContext';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import API_HOST from '../config/config';

export interface LikeButtonProps {
  className?: string;
  liked?: boolean;
  productId?:number;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  className = "",
  liked,
  productId
}) => {
  const [isLiked, setIsLiked] = useState(liked);
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const doLike = () => {
    if(user){
      try {
        axios.post(API_HOST+'/api/add-to-wishlist',{
          product_id : productId,
          user_id:user.id,
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setIsLiked(response.data.data.like);
          }
        })
        .catch(error => {
          var newArray: Array<any> = [];              
          if(!error?.response)
          {
            newArray["server_error" as any] = "No Server Response";
          }
          else if(error.response.data.success == 2)
          {
            if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
            {                  
              // Object.keys(error.response.data.data).forEach((item:any, index:any) => {
              //   newArray[item] = error.response.data.data[item].toString();
              // });                  
            }
            else
            {
              //newArray['server_error' as any] = error.response.data.message;
            }
          }
          else if(error.response?.status === 409 )
          {
            //newArray["server_error" as any] = "Login Failed";
          }
          else{
            //newArray['server_error' as any] = "Login Failed";
          }
          
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }  
      //call api for add to wishlist
      console.log('addto wishlist'+productId);
      
    } else {
      //else redirect to login
      navigate('/login');
    }

  };

  return (
    <button
      className={`w-6 h-6 sm:w-7 sm:h-7 md:w-9 md:h-9 lg:w-9 lg:h-9 xl:w-9 xl:h-9  flex items-center justify-center rounded-full bg-white  text-neutral-700  nc-shadow-lg ${className}`}
      onClick={() => doLike()}
    >
      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 xl:w-5 xl:h-5  " viewBox="0 0 24 24" fill="none">
        <path
          d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
          stroke={isLiked ? "#ef4444" : "currentColor"}
          fill={isLiked ? "#ef4444" : "none"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default LikeButton;
