import React, { FC, useEffect, useState } from "react";
//import imageRightPng from "images/hero-right.png";
//import imageRightPng2 from "images/hero-right-2.png";
//import imageRightPng3 from "images/hero-right-3.png";
// import imageRightPng4 from "images/hero-right-4.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import axios from 'axios';
import API_HOST from '../../config/config';

//images
import mobilebannerone from '../../images/mobile-banner2.png'
// import tshirt from '../../images/collections/tishirt.png'
// import corsets from '../../images/collections/Corsets.png'
// import shirt from '../../images/collections/shirt.png'
// import kurtis from '../../images/collections/kurti.png'

interface Hero2DataType {
  id: number;
  image: string;
  heading: string;
  sub_heading: string;
  btn_text?: string;
  btn_link?: string;
}
export interface SectionHero2Props {
  className?: string;
}

// const DATA: Hero2DataType[] = [
//   // {
//   //   image: imageRightPng2,
//   //   heading: "Jewelry",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng3,
//   //   heading: "Grocery",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng,
//   //   heading: "Beauty",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng4,
//   //   heading: "Exclusive collection for everyone",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
// ];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const [isLoaing, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.post(API_HOST + '/api/get-home-data', {
          nopaginate: '1',
          status: '1',
          order: 'asc',
          type: 'banners'
        })
          .then(response => {
            if (response.data.success === 0) {
              setBanners(response.data.data.banners);
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setIsLoading(false)
  }, [banners]);
  // useInterval(
  //   () => {
  //     handleAutoNext();
  //   },
  //   isRunning ? 5500 : null
  // );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= banners.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= banners.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return banners.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const renderItem = (banner: Hero2DataType, index: number) => {

    const isActive = indexActive === index;
    const item = banner;
    if (!isActive) {
      return null;
    }
    return (
<div
  className={`nc-SectionHero2Item aspect-[10/4] md:aspect-[11/4] lg:aspect-[13/4] nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
  key={index}
>
        <div className="absolute bottom-2 md:bottom-5 left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {banners.map((banner: Hero2DataType, index) => {
            const isActive = indexActive === index;
            return (
              <div
                key={index}
                onClick={() => {
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-0.5 md:px-1 py-1.5 cursor-pointer`}
              >
                <div
                  className={`relative w-6 md:w-20 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={` absolute w-6 md:w-20 inset-0 bg-[#be1e2d] rounded-md ${isActive ? " " : " "
                        }`}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Prev
          className="absolute left-2 sm:left-5 top-[42%] md:top-[48%] sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="main-slider-btn  w-6 h-6 md:w-10 md:h-10"
          svgSize="w-3 h-3 md:w-6 md:h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-2 sm:right-5 top-[42%] md:top-[48%] sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="main-slider-btn w-6 h-6 md:w-10 md:h-10"
          svgSize="w-3 h-3 md:w-6 md:h-6"
          onClickNext={handleClickNext}
        />

        {/* BG */}
        <div className="absolute inset-0 bg-[#f7f5ff]">
          {/* <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          <img
            className="w-full h-full object-cover object-center nc-SectionHero2Item__image desktopbanner "
            src={item.image}
            alt={item.heading}
          /> */}

        <img
            className="w-full h-full object-cover object-center desktopbanner nc-SectionHero2Item__image "
            src={item.image}
            alt={item.heading}
          />

          {/* Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          {/* <img
            className="w-full h-full object-cover object-center nc-SectionHero2Item__image mobilebanner block md:hidden"
            src={mobilebannerone}
            alt={item.heading}
          /> */}
        </div>

        <div className="main-slider-bx w-full max-w-[70%]  md:max-w-full absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 lg:container px-5 pb-10 pt-8 md:pt-10 sm:pt-10 sm:pb-10 lg:py-20 text-center">
          <div className={`relative z-[1] w-full mx-auto max-w-full sm:max-w-xl md:max-w-[550px] lg:max-w-[550px] xl:max-w-2xl 2xl:max-w-3xl space-y-4 sm:space-y-8 nc-SectionHero2Item__left flex flex-col items-center justify-center h-full`}
          >
            <div className="space-y-2 sm:space-y-6 flex flex-col items-center justify-center">
            <span className="nc-SectionHero2Item__subheading block text-xs sm:text-sm md:text-xl text-white font-medium text-center custom-textsgadow mobile-sub-heading" >
                {item.sub_heading}
              </span>
              <h2 className="nc-SectionHero2Item__heading font-semibold text-sm sm:text-4xl md:text-4xl xl:text-5xl 2xl:text-6xl !leading-[114%] text-white text-center custom-textsgadow mobile-heading">
                {item.heading}
              </h2>
            </div>

            <ButtonPrimary
              className="bg-white border-white nc-SectionHero2Item__button mx-auto hidden md:block"
              sizeClass="py-3 px-5 sm:py-3 sm:px-5"
              href={item.btn_link as any} onClick={() => { if (item.btn_link) { javascript: void (0); } }}

            >
              <span>{item.btn_text}</span>
              <span>
                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                </svg>

              </span>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  return <>
    {banners.length == 0 &&
      <div className="skeletant-target">
        <div className="skeletant-design ld-no-radius aspect-[11/4] md:aspect-[11/4] lg:aspect-[13/4] w-full mb-8"></div>
      </div>
    }
    {banners.map((banner: Hero2DataType, index) => renderItem(banner, index))}</>;
};

export default SectionHero2;
