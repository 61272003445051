import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const navigate = useNavigate();

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderPhoneIcon = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
    </svg>
    );
  };

  const renderEmailIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/search/"+(inputRef.current ? inputRef.current.value : ''));
        }}
        className="flex-1 py-5 text-slate-900 "
      >
        <div className="bg-slate-50 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex justify-between min-h-[65px]">
        <div className="flex items-center lg:hidden flex-1">
          <MenuBar />
        </div>

        <div className="hlrsbs lg:flex-1 flex items-center ">
          <Logo className="flex-shrink-0 header-logo" />
        </div>

        <div className="flex-[2] hidden lg:flex justify-center mx-4">
          {showSearchForm ? renderSearchForm() : <Navigation />}
        </div>

        <div className="hlrsbs flex-1 flex items-center justify-end text-slate-700 ">
          {!showSearchForm && (
            <button
              className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700  hover:bg-slate-100  focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )}
          <AvatarDropdown />
          <CartDropdown />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white  border-slate-100" style={{ boxShadow: "0 0 50px -10px rgb(0 0 0 / 20%)" }}>
    <div className="bg-[#fece40] p-1">
      <div className="container px-[5px] sm:px-[1rem]">
        <div className="flex items-center justify-end py-0 sm:py-0 md:py-0   lg:pb-0 lg:py-0">
          <div className="header-top-conatct">
            <a href={"tel:+91884 974 8151"} className="inline-flex items-center text-[10px] lg:text-[14px] xl:text-[14px] font-medium text-black  py-1 px-2.5 xl:px-5 rounded-full ">{renderPhoneIcon()}(+91) 884 974 8151</a>
            <a href={"mailto:tikasaheb@gmail.com"} className="inline-flex items-center text-[10px] lg:text-[14px] xl:text-[14px] font-medium text-black py-1 px-2.5 xl:px-5 rounded-full">{renderEmailIcon()}tikasaheb@gmail.com</a>
          </div>
          <div className="header-top-social hidden md:flex">
            <a href={"https://www.facebook.com/tikasheb/"} className="inline-flex items-center text-[18px] font-medium text-slate-700  py-3 px-3 rounded-full hover:text-slate-900 hover:bg-slate-100" target="_blank"><i className="lab la-facebook-f"></i></a>
            <a rel="noopener noreferrer" href={"https://www.instagram.com/tikasaheb/"} className="inline-flex items-center text-[18px] font-medium text-slate-700  py-3 px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 " target="_blank"><i className="lab la-instagram"></i></a>
            <a href={"https://x.com/tikasaheb"} className="inline-flex items-center text-[18px] font-medium text-slate-700  py-3 px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 " target="_blank"><i className="lab la-twitter" ></i></a>
          </div>
        </div>
      </div>
    </div>
    <div className="container relative">{renderContent()}</div>
  </div>
  );
};

export default MainNav2Logged;
