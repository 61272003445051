import React from "react";
import Slider from "react-slick";

// Import images
import reviewome from '../../images/review/2.jpg';
import reviewtwo from '../../images/review/4.jpg';
import reviewthree from '../../images/review/5.jpg';
import reviewfour from '../../images/review/6.jpg';

const Clientreview = () => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,  // Default number of slides to show
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,  // For screens under 1024px
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 768,  // For screens under 768px
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    };

    const reviews = [
        {
            id: 1,
            image: reviewthree,
            name: "Sharika Shindhe",
            review: "I recently purchased a pair of Tikasaheb, and I''m thoroughly impressed. The fit is perfect, and the fabric feels incredibly durable. These jeans have quickly become my go-to for both casual and semi-formal occasions. Highly recommend  ",
        },
        {
            id: 2,
            image: reviewtwo,
            name: "Abhay Raval",
            review: "I m in love with my new Tikasaheb! The quality is top-notch, and they offer an excellent range of styles and sizes. These jeans provide the perfect blend of comfort and style. Tikasaheb has earned a loyal customer in me!",
        },
        {
            id: 3,
            image: reviewome,
            name: "Priya Patel",
            review: "Comfort meets style with Tikasaheb. The denim is soft yet sturdy, and the design is modern and sleek. I love how versatile they are, making them suitable for any occasion. I’ll be buying more soon.",
        },
        {
            id: 4,
            image: reviewfour,
            name: "Mahir Sharma",
            review: "Tikasaheb are a game-changer! The attention to detail and craftsmanship is evident in every stitch. They fit like a dream and hold their shape even after multiple washes. Definitely worth every penny.",
        }
    ];
    

    return (
        <div className="slider-container review-slider">
            <div className="text-center">
                <h2 className="text-sm font-semibold uppercase text-gray-500 tracking-wide mb-4">Reviews</h2>
                <h3 className="text-3xl md:text-4xl font-bold mb-8">Love We Received</h3>
            </div>

            <Slider {...settings}>
                {reviews.map((review) => (
                    <div key={review.id}>
                        <div className="flex flex-col items-center text-center p-2 transition-transform duration-700 scale-105 opacity-90">
                            <img 
                                src={review.image} 
                                alt={review.name} 
                                className="w-24 h-24 md:w-32 md:h-32 rounded-full object-cover shadow-lg transition-all duration-500" 
                            />
                            <h3 className="mt-4 text-md font-semibold">{review.name}</h3>
                            <div className="flex space-x-0.5 mt-1">
                                {Array(5).fill(0).map((_, idx) => (
                                    <svg 
                                        key={idx}
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-4 w-4 text-yellow-500" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor"
                                    >
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.905c.969 0 1.371 1.24.588 1.81l-3.97 2.883a1 1 0 00-.364 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.97-2.882a1 1 0 00-1.175 0l-3.97 2.882c-.783.57-1.838-.196-1.538-1.118l1.518-4.674a1 1 0 00-.364-1.118L2.96 9.101c-.783-.57-.381-1.81.588-1.81h4.905a1 1 0 00.95-.69l1.518-4.674z"></path>
                                    </svg>
                                ))}
                            </div>
                            <p className="mt-2 text-sm md:text-md text-slate-900 transition-all duration-700 ease-in-out font-medium opacity-100 translate-y-0">
                                {review.review}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Clientreview;
