import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

const PagePrivacy: FC = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy || Tikasaheb</title>
            </Helmet>

            <BgGlassmorphism />

            <section className="pt-8 pb-14 lg:pb-20 xl:pb-20 relative z-10">
                <div className="container">

                    {/* Breadcrumb */}
                    <nav className="flex mb-4" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-[#be1e2d] transition duration-300">
                                    <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </Link>
                            </li>
                            <li aria-current="page">
                                <div className="flex items-center">
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2">Privacy Policy</span>
                                </div>
                            </li>
                        </ol>
                    </nav>

                    <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-left">
                        👋 Privacy Policy
                    </h2>

                    <div className="">
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Tikasaheb.com portal and other relevant social media portals are owned and operated by Tikasaheb. In this Privacy Policy, Tikasaheb is referred to as "we," "us," or "our'' and the end users are referred to as "you", "your" or "user".
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We may change this Privacy Policy at any time by posting the revised Privacy Policy in the "Privacy Policy" section of the website. The revised Privacy Policy is effective immediately when posted on the website. It is the responsibility of each user to review the website and the Privacy Policy periodically to know about any revisions to this Policy. Your continued use of the website will be considered as your acknowledgment and acceptance of the terms of the revised Privacy Policy.
                        </p>

                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">Information we collect when you visit our website</h2>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Web Server Log Information</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We use a third party server to host our website. Our website server automatically logs the IP address you use to access our website as well as other information about your visit such as the pages accessed, information requested, the date and time of the request, the source of your access to our website (e.g. the website or URL (link) which referred you to our website), and your browser version and operating system.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Use of website server log information for IT security purposes</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Our third party hosting provider stores server logs to ensure network and IT security and so that the server and website remain uncompromised. This includes analyzing log files to help identify and prevent unauthorized access to our network, the distribution of malicious code, denial of services attacks and other cyber attacks, by detecting unusual or suspicious activity.
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Unless we are investigating suspicious or potential criminal activity, we do not make, nor do we allow our website server provider to make any attempt to identify you from the information collected via server logs.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Cookies</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Cookies are data files which are sent from a website to a browser to record information about users for various purposes. We use cookies, including essential, functional and analytical cookies. You can reject some or all of the cookies we use on or via our website by changing your browser settings, but doing so can impair your ability to use our website or some or all of its features.
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6text-sm md:text-base lg:text-md xl:text-md">
                            For each user, our web server automatically recognizes the following:
                        </p>
                        
                        <ul className="mb-1 text-sm sm:text-base md:text-md lg:text-lg">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Host origin of the request.</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Referrer</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Session data</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Page viewed</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Your browser type</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Operating system information</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Tikasaheb uses cookies to ensure Tikasaheb.com runs on your computer and you can access content on our site. Cookies do not store any passwords or credit card information.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Use of cookies</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">There are a number of different ways in which you can accept or reject some or all cookies. Some of the main methods of doing so are mentioned below.</p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Changing Browser Settings</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Google Analytics Opt-out Add Ons</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Clearing Existing cookies</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            You are welcome to block the use of some or all of the cookies we use on our website. However, please be aware that doing so may impair our website and its functionality or may even render some or all of it unusable.
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            You should also be aware that clearing all cookies from your browser will also delete any cookies that are storing your preferences, for example, whether you have accepted cookies on a website or any cookies that are blocking other cookies.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">How we use your information</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Information we collect when you contact us
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Email Id</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Contact Number</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Address</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Measurement Sizes (If applicable)</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We use a third party email provider to store emails you send us.
                        </p>

                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">Information we collect when you interact with our website</h2>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Subscription/Newsletters</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            You can opt in to receiving marketing communications from us in relation to our goods and services by keeping the ‘I would like to receive offers about products and services’. Box ticked and giving a confirmation on the same when engaging with our portals. We will send you marketing communications in relation to our goods and services only if you opt-in to receive them. When you give consent to our e-newsletter to receive news and offers about our products and services on our website we collect your email address and device OS and country code. This information is stored with our 3rd party mail server providers as well as our hosting servers.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Registration/ Checkout/ Guest Checkout</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            When you register and create an account on our website or at checkout, we collect the following information: your name, your shipping address (including country, street address, town/city and postcode), your billing address (including country, street address, town/city and postcode) phone number, email address and password (we do not view your password) which we store on our servers(except plain password) for better user experience next time the same customer visits our portal.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Measurements:</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            When you add stitchable products to cart, we collect measurements data which may be either body measurements or garment measurements which we store on our servers for better user experience next time the same customer visits our portal.
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Information collected while placing an order
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            After you place an order on our website you will need to make payment for the goods or services you have ordered. In order to process your payment we use a number of third party payment processors, including PayPal, RazorPay and PayU. Your payment will be processed by the payment provider you choose to process your payment via a payment gateway.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Third party payment processors</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">The third party payment processors we use all collect, use and process your information, including payment information, in accordance with their privacy policies. You can access their privacy policies via the following links:</p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1">RazorPay</li>
                        </ul>
                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">Disclosure and additional uses of your information</h2>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Disclosure to Service Providers</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We use a number of third parties to provide us with services which are necessary to run our business or to assist us with running our business and who process your information for us on our behalf. These include the following:
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Email provider</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Mailing list provider</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">IT service providers</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Web developers</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Hosting provider</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Your information will be shared with these service providers only where necessary to enable us to run our business.
                        </p>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Disclosure for Legal Reasons</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">Indicating possible criminal acts or threats to public security to a competent authority</p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">In connection with the enforcement or potential enforcement our legal rights</p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">In connection with a legal or potential legal dispute or proceedings</p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">For ongoing compliance with laws, regulations and other legal requirements</p>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Disclosure to other 3rd Party Providers</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                        We may provide user data to 3rd party providers which includes but is not limited to the parties mentioned below
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Providing information to 3rd Party Analytics Partners</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Accountants</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Advisors</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Affiliates</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Business Partners</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Independent Contractors</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Insurers</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">PayU</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Sponsors</li>
                        </ul>

                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">How long we retain your information</h2>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            When you place an order for goods and services, we retain that information for a minimum period of seven years following the end of the financial year in which you placed your order, in accordance with our legal obligation to keep records.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Criteria for Determining the retention period</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            In any other circumstances, we will retain your information for no longer than necessary, taking into account the following:
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">The purpose(s) and use of your information both now and in the future</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Legal obligations to continue processing your information</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Legal basis to continue processing your information</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">The value of your information (both now and in the future)</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Relevant agreed industry practices on retention periods</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Levels of risk, cost and liability involved with continued retention</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Difficulty in keeping the information up to date and accurate</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Relevant surrounding circumstances</li>
                        </ul>

                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">Personal Information</h2>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We collect personal information when you register with us, participate in reviews, or use other features. When you place an order, we collect your name, email address, physical address, and phone number. We also collect search keywords in a protected location to improve our search results.
                        </p>

                        <h2 className="text-2xl font-semibold text-custom-pink mb-4">Information Use and Sharing</h2>
                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Legal Requirements</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We reserve the right to disclose your personal information as necessary to:
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Comply with the law or legal process served on Tikasaheb</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Protect and defend the rights or property of Tikasaheb</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Ensure compliance with our Terms & Conditions</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Tikasaheb cooperates with law enforcement and other third parties to enforce laws, intellectual property, and other rights. We reserve the right to report any information related to suspicion of illegal activity to law enforcement for prosecution.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Communication</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We may send you details about new services and updates. You can opt out by emailing <a href="mailto:tikasaheb@gmail.com" className="text-red-500 underline">tikasaheb@gmail.com</a>.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Payments</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            For credit/debit card purchases, your card number and billing information will be passed on to the relevant financial institution.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Third Parties</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            We will not disclose your personal information to third parties. Tikasaheb.com may use aggregated, non-personally identifiable information for its own purposes or share it with advertisers, business partners, and sponsors.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Business Transfers</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Information about Tikasaheb.com users, including personal information, may be disclosed as part of any merger, acquisition, debt financing, sale of company assets, or in the event of insolvency, bankruptcy, or receivership.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Miscellaneous Privacy Issues</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Minors under 18 may not use the website. We do not knowingly collect information from anyone under 18. Children's products are for purchase by adults only. If you are under 18, you may use Tikasaheb.com only with parental involvement.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Advertising</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Tikasaheb uses remarketing with Google to advertise online. Third-party vendors, including Google, show our ads on the internet. Tikasaheb and third-party vendors use first-party and third-party cookies to inform, optimize, and serve ads based on past website visits.
                        </p>

                        <h3 className="text-xl font-semibold text-custom-pink mb-4">Your rights in relation to your information</h3>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Subject to certain limitations, you have the following rights regarding your information:
                        </p>
                        <ul className="list-disc pl-4 mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6">
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Request access to your information and related processing details</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Request correction or deletion of your information</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Request restrictions on our use of your information</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Receive your provided information in a structured, machine-readable format</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Object to certain processing of your information</li>
                            <li className="mb-1 text-sm md:text-base lg:text-md xl:text-md">Withdraw consent for our use of your information</li>
                        </ul>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            To exercise these rights, contact us at:
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            Tikasaheb<br />
                            608, Colonnade - 2, B/H.Rajpath Club,<br />
                            Rajpath Rangoli Road, Bodakdev,<br />
                            Ahmedabad - 380054, India<br />
                            Email: tikasaheb@gmail.com
                        </p>
                        <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-md xl:text-md">
                            You also have the right to lodge a complaint with a supervisory authority, particularly in your habitual residence, place of work, or place of an alleged infringement of the General Data Protection Regulation.
                        </p>

                        {/* <h2 className="text-xl font-medium text-custom-pink mb-2">Handcrafted Excellence</h2>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              Every item in our collection is a result of dedicated craftsmanship and attention to detail. From the selection of premium ingredients to the intricate blending processes, our bath bombs and salts are crafted with care to ensure the highest standards of quality. Our commitment to sustainability and ethical practices means you can indulge in our products with peace of mind, knowing they are made with respect for both people and the planet.
            </p> */}

                    </div>

                    {/* <div className="pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 items-center relative">

                <div className="flex-grow">
                  <img className="w-full" src={visionmission} alt="" />
                </div>

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content">
                    <div className="main-sub-lable">Vision</div>
                    <p>To be a globally recognized leader, inspiring positive change and innovation across diverse industries. Tikasaheb General Trading envisions a future where our comprehensive range of services transforms lives, businesses, and experiences, leaving an indelible mark on the world.</p>

                    <div className="pt-3"></div>

                    <div className="main-sub-lable">Mission</div>
                    <p>Tikasaheb General Trading is dedicated to delivering unparalleled excellence across Spice Trading, Organic Foods Handmade Bath and Body products, IT services, Investment Solutions, E-commerce, Handmade Bath and Body products and VR solutions. Our mission is to empower individuals and businesses through strategic, innovative, and sustainable practices. We are committed to fostering growth, embracing diversity, and exceeding expectations to create lasting value for our clients and communities.</p>
                  </div>
                </div>

          </div> */}

                </div>
            </section>


        </div>
    );
};

export default PagePrivacy;
