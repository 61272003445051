import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import axios from 'axios';
import API_HOST from '../../config/config';

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {

  const inputRef = React.createRef<HTMLInputElement>();
  const navigate = useNavigate();
  const [headerCategory, setHeaderCategory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.post(API_HOST + '/api/get-category-list', {
          nopaginate: '1',
          status: '1',
          order: 'asc',
        })
          .then(response => {
            if (response.data.success === 0) {
              setHeaderCategory(response.data.data.categories);
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const _renderMenuChild = (
    item: NavItemType,
    itemClass = " pl-3 text-neutral-900  font-medium "
  ) => {

    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex text-sm rounded-lg hover:bg-neutral-100  mt-0.5 pr-4 ${itemClass} ${isActive ? "text-custom-golden" : ""
                }`
              }
            >
              <span
                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-slate-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 "
      >
        <NavLink
          className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 rounded-lg ${isActive ? "text-custom-golden" : ""}`}
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const handleSearchSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate("/search/" + (inputRef.current ? inputRef.current.value : ''));
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={handleSearchSubmit}
        className="flex-1 text-slate-900"
      >
        <div className="bg-slate-100 flex items-center space-x-1 py-2 px-4 rounded-0 h-full">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="search"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
          />
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1  bg-white divide-y-2 divide-neutral-100">
      <div className="mt-2 sm:mt-6 md:mt-6 lg:mt-6 xl:mt-6 py-4 px-4 block sm:block md-flex lg-flex xl-flex items-center gap-2">
        <Logo className="sidemenu-logo" />
        <div>{renderSearchForm()}</div>
        <div className="hidden flex-col mt-5 text-slate-600 text-sm">
          <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
            <span className="hidden">
              <SwitchDarkMode className="bg-neutral-100 " />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>

      {/* <ul className="flex flex-col py-6 px-2 space-y-1">

        <div className="text-custom-pink dark:text-custom-pink flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm">
        Categories
        </div>
        {headerCategory.map(_renderItem)}

      </ul> */}

      <ul className="flex flex-col py-6 px-2 space-y-1">

        {/* <div className="text-custom-pink dark:text-custom-pink flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm">
          Usefull Liks
        </div> */}
{/* 
        <li className="text-slate-900" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 rounded-lg ${isActive ? "text-custom-pink" : ""}`} to="/">
            <span onClick={onClickClose} className="block w-full">Kurtis</span>
          </NavLink>
        </li>
        

        <li className="text-slate-900" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 rounded-lg ${isActive ? "text-custom-pink" : ""}`} to="/about">
            <span onClick={onClickClose} className="block w-full">About Us</span>
          </NavLink>
        </li> */}

        {/* <li className="text-slate-900 dark:text-white" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${ isActive ? "text-custom-pink" : "" }`} to="/category">
            <span onClick={onClickClose} className="block w-full">Products</span>
          </NavLink>
        </li> */}

        <li className="relative text-slate-900 group">
          <NavLink
            className={({ isActive }) =>
              `flex w-full items-center justify-between py-2.5 px-4 font-medium uppercase tracking-wide text-sm   
            hover:bg-slate-100  rounded-0 
            ${isActive ? "text-custom-pink" : ""}`
            }
            to="#"
            onClick={toggleDropdown} // Toggle on click
          >
            <span onClick={onClickClose} className="block">Kurtis</span>
            {/* Down arrow icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`w-4 h-4 transition-transform duration-200 ease-in-out transform ${isOpen ? "rotate-180" : ""
                }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </NavLink>

          {/* Dropdown Menu */}
          <div
            className={`relative left-0 mt-0 w-[95%] bg-white ml-2 rounded-lg  p-2
          transition-all duration-200 ease-in-out transform ${isOpen ? "opacity-100 visible scale-100 translate-y-0" : "opacity-0 hidden scale-95 -translate-y-2"
              }`}
          >
            <NavLink
              to="/category"
              className="block px-4 py-2 text-sm text-gray-700 hover:text-custom-pink hover:bg-[#f7f5ff] rounded-0"
            >
              Semi-stitched Kurtis
            </NavLink>
          </div>
        </li>


        <li className="text-slate-900 " data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100  ${isActive ? "text-custom-pink" : ""}`} to="/category/sarees">
            <span onClick={onClickClose} className="block w-full">Sarees</span>
          </NavLink>
        </li>

        <li className="text-slate-900 " data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 ${isActive ? "text-custom-pink" : ""}`} to="/category/corsets">
            <span onClick={onClickClose} className="block w-full">Corsets</span>
          </NavLink>
        </li>

        <li className="text-slate-900 " data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 ${isActive ? "text-custom-pink" : ""}`} to="/category/shirts">
            <span onClick={onClickClose} className="block w-full">Shirts</span>
          </NavLink>
        </li>

        <li className="text-slate-900 " data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 ${isActive ? "text-custom-pink" : ""}`} to="/category/sale">
            <span onClick={onClickClose} className="block w-full">Sale</span>
          </NavLink>
        </li>

        <li className="text-slate-900" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100  ${isActive ? "text-custom-pink" : ""}`} to="/contact">
            <span onClick={onClickClose} className="block w-full">Contact Us</span>
          </NavLink>
        </li>

        {/* <li className="text-slate-900 dark:text-white" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${ isActive ? "text-custom-pink" : "" }`} to="/terms">
            <span onClick={onClickClose} className="block w-full">Terms & Conditions</span>
          </NavLink>
        </li>
        
        <li className="text-slate-900 dark:text-white" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${ isActive ? "text-custom-pink" : "" }`} to="/contact">
            <span onClick={onClickClose} className="block w-full">Contact</span>
          </NavLink>
        </li>
        
        <li className="text-slate-900 dark:text-white" data-headlessui-state="">
          <NavLink className={({ isActive }) => `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${ isActive ? "text-custom-pink" : "" }`} to="/sitemap">
            <span onClick={onClickClose} className="block w-full">Sitemap</span>
          </NavLink>
        </li> */}

      </ul>

      <ul className="flex flex-col py-6 px-2 space-y-1">

        <div className="text-custom-pink flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm">
          Social Links
        </div>

        <div className="px-4 py-2">
          <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
        </div>

      </ul>

      {/* <div className="flex items-center justify-between py-6 px-5 space-x-2">
        <ButtonPrimary href={"/"} className="!px-10">
          Buy this template
        </ButtonPrimary>
      </div> */}
    </div>
  );
};

export default NavMobile;
